<template>
  <div>
    <v-row class="mt-4 mx-4">
      <v-col cols="6">
        <v-card>
          <v-card-title><v-icon class="mr-2">mdi-account</v-icon>My Account</v-card-title>

          <v-card-text>
            <v-row dense>
              <v-col cols="4">
                <v-text-field :value="value.email" v-model.trim="value.email" label="Email" disabled></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field :value="value.phone" v-model.trim="value.phone" label="Phone" disabled></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  :value="value.displayName"
                  v-model.trim="value.displayName"
                  label="Display Name"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field :value="value.firstName" v-model.trim="value.firstName" label="First Name"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field :value="value.lastName" v-model.trim="value.lastName" label="Last Name"></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="auto">
                <v-btn color="green" dark @click="onSubmit">Save</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col col="6">
        <v-card>
          <v-card-title>Reset Password</v-card-title>

          <v-card-text>
            <v-form :value="$v.passwordForm.$invalid">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showCurrent ? 'text' : 'password'"
                    @click:append="showCurrent = !showCurrent"
                    :rules="handleErrors($v.passwordForm.currentPassword)"
                    label="Current / Temporary Password"
                    required
                    v-model.trim="$v.passwordForm.currentPassword.$model"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNew ? 'text' : 'password'"
                    :rules="[
                      ...handleErrors($v.passwordForm.password),
                      ...handleErrors($v.passwordForm.passwordStrength),
                    ]"
                    @click:append="showNew = !showNew"
                    label="New Password"
                    required
                    v-model.trim="$v.passwordForm.password.$model"
                  ></v-text-field>

                  <password-strength
                    :password="$v.passwordForm.password.$model"
                    v-model="$v.passwordForm.passwordStrength.$model"
                  ></password-strength>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirm ? 'text' : 'password'"
                    :rules="handleErrors($v.passwordForm.passwordConfirm, 'New Password')"
                    @click:append="showConfirm = !showConfirm"
                    label="Confirm New Password"
                    required
                    v-model.trim="$v.passwordForm.passwordConfirm.$model"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="auto">
                  <v-btn color="success" @click="resetPassword" :disabled="$v.passwordForm.$invalid"
                    >Update Password</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';

import { handleErrors } from '@/util';
import { UPDATE_MYSELF, UPDATE_PASSWORD } from '@/store/modules/User/actions';

import PasswordStrength from '@/components/PasswordStrength.vue';
import PasswordFormValidations from '@/models/PasswordFormValidation.model';
import required from 'vuelidate/lib/validators/required';

export default {
  name: 'MyAccount',
  inject: ['eventHub'],
  mixins: [validationMixin],
  components: { PasswordStrength },
  data() {
    return {
      handleErrors,
      passwordForm: {
        currentPassword: '',
        password: '',
        passwordStrength: 0,
        passwordConfirm: '',
      },
      showConfirm: false,
      showCurrent: false,
      showNew: false,
      value: {},
    };
  },
  validations: {
    passwordForm: {
      currentPassword: {
        required,
      },
      ...PasswordFormValidations(3),
    },
  },
  computed: {
    ...mapGetters('user', ['me']),
  },
  mounted() {
    this.value = {
      email: this.me.email,
      phone: this.me.phone,
      displayName: this.me.displayName,
      firstName: this.me.firstName,
      lastName: this.me.lastName,
    };
  },
  methods: {
    ...mapActions('user', [UPDATE_MYSELF, UPDATE_PASSWORD]),
    async onSubmit() {
      const response = await this[UPDATE_MYSELF]({ ...this.me, ...this.value });

      if (response.done) this.$myalert.success('Account updated');
      else this.$myalert.error('Unable to update account');
    },
    async resetPassword() {
      if (this.$v.passwordForm.$invalid) return;

      try {
        const r = await this[UPDATE_PASSWORD]({
          oldPassword: this.$v.passwordForm.currentPassword.$model,
          newPassword: this.$v.passwordForm.password.$model,
        });

        if (r && r.done) this.$myalert.success('Password updated');
        else this.$myalert.error('Unable to update password');
      } catch (e) {
        this.$myalert.error('Unable to update password: ' + e.message);
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
