var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-4 mx-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-account"),
                      ]),
                      _vm._v("My Account"),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.email,
                                  label: "Email",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.value.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "value.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.phone,
                                  label: "Phone",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.value.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value,
                                      "phone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "value.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.displayName,
                                  label: "Display Name",
                                },
                                model: {
                                  value: _vm.value.displayName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value,
                                      "displayName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "value.displayName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.firstName,
                                  label: "First Name",
                                },
                                model: {
                                  value: _vm.value.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value,
                                      "firstName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "value.firstName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.lastName,
                                  label: "Last Name",
                                },
                                model: {
                                  value: _vm.value.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value,
                                      "lastName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "value.lastName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "green", dark: "" },
                                  on: { click: _vm.onSubmit },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { col: "6" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Reset Password")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        { attrs: { value: _vm.$v.passwordForm.$invalid } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": _vm.showCurrent
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.showCurrent
                                        ? "text"
                                        : "password",
                                      rules: _vm.handleErrors(
                                        _vm.$v.passwordForm.currentPassword
                                      ),
                                      label: "Current / Temporary Password",
                                      required: "",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showCurrent = !_vm.showCurrent
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.passwordForm.currentPassword
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.passwordForm.currentPassword,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.passwordForm.currentPassword.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": _vm.showNew
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.showNew ? "text" : "password",
                                      rules: [
                                        ..._vm.handleErrors(
                                          _vm.$v.passwordForm.password
                                        ),
                                        ..._vm.handleErrors(
                                          _vm.$v.passwordForm.passwordStrength
                                        ),
                                      ],
                                      label: "New Password",
                                      required: "",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showNew = !_vm.showNew
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.passwordForm.password.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.passwordForm.password,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.passwordForm.password.$model",
                                    },
                                  }),
                                  _c("password-strength", {
                                    attrs: {
                                      password:
                                        _vm.$v.passwordForm.password.$model,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.passwordForm.passwordStrength
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.passwordForm.passwordStrength,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.passwordForm.passwordStrength.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": _vm.showConfirm
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.showConfirm
                                        ? "text"
                                        : "password",
                                      rules: _vm.handleErrors(
                                        _vm.$v.passwordForm.passwordConfirm,
                                        "New Password"
                                      ),
                                      label: "Confirm New Password",
                                      required: "",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showConfirm = !_vm.showConfirm
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.passwordForm.passwordConfirm
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.passwordForm.passwordConfirm,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.passwordForm.passwordConfirm.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "success",
                                        disabled: _vm.$v.passwordForm.$invalid,
                                      },
                                      on: { click: _vm.resetPassword },
                                    },
                                    [_vm._v("Update Password")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }